var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-list-gallery-overlay"
  }, [_c('v-row', _vm._l(_vm.boards, function (board) {
    return _c('v-col', {
      key: board._id,
      attrs: {
        "cols": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c('div', {
      staticClass: "board-list-gallery-overlay__inner"
    }, [_c('a', {
      staticClass: "board-list-gallery-overlay__link",
      attrs: {
        "href": board.meta.href,
        "target": "_blank"
      }
    }, [_c('div', {
      staticClass: "board-list-gallery-overlay__img",
      style: {
        backgroundImage: `url('${board.thumb}')`
      }
    }), _c('div', {
      staticClass: "board-list-gallery-overlay__fixed"
    }, [_c('i', {
      staticClass: "icon icon-insta-lg"
    })]), _c('div', {
      staticClass: "board-list-gallery-overlay__con"
    }, [_c('div', {
      staticClass: "board-list-gallery-overlay__tit"
    }, [_vm._v(_vm._s(board.subject))]), _c('div', {
      staticClass: "board-list-gallery-overlay__arrow"
    }, [_c('i', {
      staticClass: "icon icon-right-white"
    })])])])])]);
  }), 1)], 1), !_vm.hidePagination ? _vm._t("pagination") : _vm._e(), _c('div', {
    staticClass: "board-buttons board-buttons--center"
  }, [!_vm.hideWriteButton ? _vm._t("write-button") : _vm._e()], 2)], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }