<template>
    <div>
        <div class="board-container">
            <div class="board-list-gallery-overlay">
                <v-row>
                    <v-col v-for="board in boards" :key="board._id" cols="6" md="4" lg="3">
                        <div class="board-list-gallery-overlay__inner">
                            <a :href="board.meta.href" class="board-list-gallery-overlay__link" target="_blank">
                                <div class="board-list-gallery-overlay__img" :style="{ backgroundImage: `url('${board.thumb}')` }"></div>
                                <div class="board-list-gallery-overlay__fixed"><i class="icon icon-insta-lg"></i></div>
                                <div class="board-list-gallery-overlay__con">
                                    <div class="board-list-gallery-overlay__tit">{{ board.subject }}</div>
                                    <!-- <div class="board-list-gallery-overlay__date">{{ board.createdAt.toDate() }}</div>
                                    <div class="board-list-gallery-overlay__txt">{{ board.summary }}</div> -->
                                    <div class="board-list-gallery-overlay__arrow"><i class="icon icon-right-white"></i></div>
                                </div>
                            </a>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <slot v-if="!hidePagination" name="pagination"></slot>

            <div class="board-buttons board-buttons--center">
                <slot v-if="!hideWriteButton" name="write-button"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        summary: {
            type: Object,
            default() {
                return { totalCount: 0 };
            },
        },
        boards: { type: Array, default: null },

        hideWriteButton: { type: Boolean, default: false },
        hidePagination: { type: Boolean, default: false },
    },
};
</script>

<style lang="scss" scoped>
/* Board Style - Gallery Overlay */
.board-list-gallery-overlay {
    width: 100%;
    .board-empty-message {
        padding: 60px 20px;
    }
    &__inner {
        position: relative;
        width: 100%;
        padding-top: 100%;
        overflow: hidden;
    }
    &__link {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
    &__img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: center;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transition: all ease-out 0.4s;
    }
    &__fixed {
        position: absolute;
        top: 20px;
        right: 20px;
        opacity: 0;
        visibility: hidden;
        transition: all ease-out 0.4s;
    }
    &__con {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 18px;
        border-top: 4px solid var(--v-primary-base);
        background-color: rgba(0, 0, 0, 0.7);
        transition: all ease-out 0.2s;
    }
    &__tit {
        font-size: 1.6rem;
        line-height: 1.5;
        color: #fff;
        max-height: 3em;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        white-space: normal;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    &__arrow {
        position: absolute;
        bottom: 20px;
        right: 20px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .board-list-gallery-overlay {
        .board-empty-message {
            padding: 20px 10px;
        }

        &__fixed {
            opacity: 1;
            visibility: visible;
        }
        &__con {
            top: 100%;
            padding: 24px;
        }
        &__tit {
            font-size: 1.8rem;
        }
        &__link:hover {
            .board-list-gallery-overlay {
                &__img {
                    opacity: 1;
                    visibility: visible;
                }
                &__fixed {
                    opacity: 0;
                    visibility: hidden;
                }
                &__con {
                    top: 0;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
    .board-list-gallery-overlay {
        .board-empty-message {
            padding: 40px 20px;
        }
    }
}
</style>
